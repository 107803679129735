$primary: #931c40; /* MAIN COLOR */
$secondary: #e39b5b; /* SECONDARY COLOR */
$trm: #b96820; /* TRIM COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;


a, p, ol, ul {
font-family: 'Belgrano', serif;
}

h1, h2, h3 {
font-family: 'Lemon', cursive;
}

.flash {
	display:none;
}

nav {
	z-index: 1000;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}



.navbar-default {border-bottom: 10px solid $primary;}


.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 80px;
		display: flex;
		align-items: center;
		color: $primary;
        outline: none;
		


		@media (max-width: 767px) {
		    height: 20px;
        line-height: 10px !important;
		    display: inline-block;
		}
	}
}


.navbar-default .navbar-nav>li>a:focus, {
	color: $primary;
}
.navbar-default .navbar-nav>li>a:hover {
	color: $secondary;
} 

.navbar-right {
	margin-top: 25px;
	font-size: 1.5em;

	@media (max-width: 1024px) {
		margin-top: 5px;
		font-size: 1.25em;
		margin-top: 0px;
		margin-right: 0px;
	  margin-left: 0px;
	  margin-bottom: 0px;
	  padding: 0px;
	}

}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

	@media (max-width: 767px) {
		margin-top: 20px;
	}
}

.fade {background: rgba(0,0,0,0.5) !important }

.modal-open {
  overflow: auto; 
  padding-right: 0px !important;
}

/** LOGIN FORM **/
.modal-dialog {
  width: 290px;
  text-align: center;
  margin: 50px auto;
}
.modal-dialog .close {
  display: none;
}
.modal-dialog .modal-content {
  color: $secondary;
}
.modal-dialog .modal-content h2 {
  text-align: center;
}
.modal-dialog input.input {
  border: none;
  border-bottom: 1px solid $trm;
  text-align: center;
  color: $blk;
}
.modal-dialog button {
  text-align: center;
  margin: 0 auto;
  border: 1px solid $secondary;
  border-radius: 0px;
  background: $secondary;
  color: $wht;
  padding: 0.5em 2em;
  background: #ededed;
  color: $secondary;
  border: none;
  display: block;
  width: 75%;
}

.modal-dialog button:hover {
  background: $secondary;
  color: $wht;
  box-shadow: 0px 0px 3px #4d4d4d;
}
.modal-dialog button:hover {
  background: $wht;
  color: $secondary;
  border: none;
}
.modal-dialog input[type="submit"] {
  text-align: center;
  margin: 0 auto;
  border: 1px solid $secondary;
  border-radius: 0px;
  background: $secondary;
  color: $wht;
  padding: 0.5em 2em;
  display: block;
  width: 75%;
}
.modal-dialog input[type="submit"]:hover {
  background: $secondary;
  color: $wht;
  box-shadow: 0px 0px 3px #4d4d4d;
}

.modal-header, .modal-footer {
  background: $secondary;
  color: $wht;
}

input#username {
  margin-bottom: 20px;
}

/** END LOGIN FORM **/
footer {
  padding: 50px 0px 20px;
  background: $secondary;
  color: $wht;
}
footer a {
  color: $wht;
}
footer a:hover {
  color: $wht;
}

.banner {
  background: url(../img/banner.jpg) no-repeat;
  background-size: cover;
  padding: 150px 0px;
  margin-top: 125px;

  @media (max-width: 1199px) {
      background-position: 75% 10%;
      margin-top: 95px;
  }

}

.logo {
  width: 100%;
  display: block;
  margin: 0 auto;
  max-width: 125px;

  @media (max-width: 1024px) {
    max-width: 75px;
    margin: 5px 5px; 

  }

@media (max-width: 767px) {
    max-width: 75px;
    margin: 5px 5px; 

  }
}
.noPad {
  padding: 0;
}

p {
  font-size: 22px;
  color: $primary;

    @media (max-width: 767px) {
      font-size: 18px;
    }

}

h1 {
  color: $primary;

  @media (max-width: 767px) {
        font-size: 2em;

      }
}

h2, h3 {
  color: $primary;

}
h2.title {
  color: $wht;
}
.introText {
  padding: 30px 15px;
}

.banner2 {
  background: url(../img/banner2.jpg) no-repeat;
  background-size: cover;
  background-position: 70% 50%;
  background-attachment: scroll;
}

.banner2-overlay {
      background-color: rgba(31, 31, 31, 0.75);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 250px 0px;
}

.banner2 h1 {
  color: $primary;
  text-shadow: 2px 2px $wht;
  font-size: 50px;
}


.banner3 {
  background: url(../img/banner3.jpg) no-repeat;
  background-size: cover;
  background-position: 70% 50%;
  background-attachment: scroll;
}

.banner3-overlay {
      background-color: rgba(31, 31, 31, 0.75);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.banner3 h1 {
  color: $primary;
  text-shadow: 2px 2px $wht;
  font-size: 50px;
}

p.pad {
  font-size: 18px;
  padding: 0px 15px;
  color: $wht;
}
.showSmall {
  display: none;
}

@media (max-width: 991px) {
  .hideSmall {
    display: none;
  }

  .showSmall {
    display: block;

     @media (max-width: 1024px) {
      margin-top: 95px;
  }
   
      @media (max-width: 767px) {
      margin-top: 95px;
  }
  }
}

.container.white {
  background: $wht;
}
button.btn.btn-large {
  display: block;
  margin: 0 auto;
  background: $secondary;
  color: $wht;
  padding: 10px 30px;
}

@media (max-width: 600px) {
  .banner2 {
    height: auto;
    background-position: 40% 50%;
  }
}
.top-pad {
  color: $primary;
  padding-top: 135px;
}